/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance } from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';

/**
 * A wrapper for axios functionality
 */
class ApiClient {
  private readonly baseUrl: string;
  private readonly authStorageKey: string;
  private readonly axiosInstance: AxiosInstance;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;

    this.axiosInstance = axios.create();

    this.authStorageKey = `CognitoIdentityServiceProvider.${process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID}`;

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true; // Mark the request as retried to avoid infinite loops.
          try {
            // Refresh the token.
            const { tokens } = await fetchAuthSession();
            if (!tokens) {
              return Promise.reject('Could not refresh auth token');
            }
            // Update the authorization header with the new access token.
            this.axiosInstance.defaults.headers.common['Authorization'] =
              `Bearer ${tokens.accessToken.toString()}`;
            return this.axiosInstance(originalRequest); // Retry the original request with the new access token.
          } catch (refreshError) {
            // Handle refresh token errors by clearing stored tokens and redirecting to the login page.
            console.error('Token refresh failed:', refreshError);
            const loginUser = localStorage.getItem(
              `${this.authStorageKey}.LastAuthUser`
            );
            const keyPrefix = `${this.authStorageKey}.${loginUser}`;
            localStorage.removeItem(`${keyPrefix}.accessToken`);
            localStorage.removeItem(`${keyPrefix}.refreshToken`);
            window.location.href = '/login';
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error); // For all other errors, return the error as is.
      }
    );
  }

  getJwtAuthToken() {
    const loginUser = localStorage.getItem(
      `${this.authStorageKey}.LastAuthUser`
    );
    const key = `${this.authStorageKey}.${loginUser}.idToken`;
    const jwt = localStorage.getItem(key);
    if (!jwt) {
      console.error('Could not find user auth.');
    }
    return jwt || '';
  }

  addJwtAuthHeader() {
    return {
      Authorization: `Bearer ${this.getJwtAuthToken()}`,
    };
  }

  /**
   * Performs a http GET
   * @param name {string} url
   */
  async get(url: string) {
    const response = await this.axiosInstance.get(`${this.baseUrl}${url}`, {
      headers: this.addJwtAuthHeader(),
    });
    return response.data?.body ? JSON.parse(response.data.body) : undefined;
  }

  /**
   * Posts data to a url
   * @param url {string} The url
   * @param data {any} The data to send
   */
  async post(url: string, data: any) {
    const response = await this.axiosInstance.post(
      `${this.baseUrl}${url}`,
      data,
      {
        headers: {
          ...this.addJwtAuthHeader(),
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  }

  /**
   * Put data to a url
   * @param url {string} The url
   * @param data {any} The data to send
   */
  async put(url: string, data: any) {
    const response = await this.axiosInstance.put(
      `${this.baseUrl}${url}`,
      data,
      {
        headers: {
          ...this.addJwtAuthHeader(),
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  }
}

export default ApiClient;
